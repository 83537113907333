/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 60px; /* Margin bottom by footer height */
}
.footer {
  position: absolute;
  max-height: 606px;
  min-height: 235px;
  bottom: 0;
  width: 100%;
  background: linear-gradient( #2A600C 2%, #519D17 55%, #64B31C);
}

.footer-container{
  padding-top: 20px;
  padding-bottom: 35px;
  font-family: 'Roboto', sans-serif;
  color: #c7ff96;
  font-size: 14px;
  h5 {
    font-family: 'Jockey One', sans-serif;
    font-size: 20px;
    color: $footer-header;
  }

  a, a:visited {
    color: #c7ff96;
  }
}

.footer-about {
}

.footer-links {
  white-space: nowrap;
  ul {
    font-size: 13px;
    list-style: none;
    padding-left: 15px;
    padding-right: 0;
  }
  li:before {
    content: "▶";
    color: #000;
    margin-left: -1em;
  }
}

.footer-location {
  li {
    font-size: 0.665em;
  }

}

.footer-copyright {
  background: #2a3542;
  padding: 9px;
  display: flex;
  justify-content: center;
  align-content: center;
  p {
    margin-bottom: 0;
    font-size: 12px;
    color: #b8b8b8;
    font-family: 'Arial' , sans-serif;
  }

  a, a:visited {
    color: #FFF;
    text-decoration: underline;
  }
}