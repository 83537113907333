header {
  border-top: 5px solid #2ba23d;
  padding-top: 20px;
  background: repeating-linear-gradient(
                  135deg,
                  #F9F9F9,
                  #FFF 10px,
                  #FFF 10px,
                  #FFF 20px
  );

  .header-location {
    text-align: center;

    .phone-two-desktop {
      display: none;
    }

    @media(min-width: 990px) {
      .phone-one {
        display: none;
      }
      .phone-two {
        display: none;
      }
      .phone-two-desktop {
        display: inline;
      }
    }

    h5 {
      font-family: 'PT Sans Narrow', sans-serif;
      font-size: 17px;
      color: #4f676b;
      text-transform: uppercase;
      font-weight: 700;
      white-space: nowrap;
    }

    a, a:visited{

      @media(max-width: 870px) {
        font-size: 22px;
      }
      font-family: 'Oswald', sans-serif;
      font-weight: 700;
      font-size: 35px;
      color: #2ba23d;
      border-top: 1px solid #e0e0e0;
      border-bottom: 1px solid #e0e0e0;
    }
    p {
      font-size: 14px;
      line-height: 24px;
      font-family: 'Open Sans', sans-serif;
      color: #8d9ea1;
      margin-bottom: 0;
    }
  }
}

.nav-container {

  margin-left: -15px !important;
  margin-right: -15px !important;
  padding-top: 13px;

  .navbar-dark .navbar-nav .nav-link {
    font-size: 18px;
    color: #fff;
    font-family: 'PT Sans Narrow', sans-serif;
    font-weight: 700;
    padding-left: 21px;
  }

  .navbar-dark .navbar-toggler {
    border: none;
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    padding: 6px 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
  }

  .navbar-mobile {
    @media (min-width: 780px) {
      display: none;
    }

    background: $alligator-orange;
    text-transform: uppercase;



    .navbar-toggler {
      margin: auto;

      .navbar-nav {
        .nav-link {

        }
      }
    }
  }

  .main-navigation {
    @media (max-width: 780px) {
      display: none;
    }

    border-top: #A13F17 3px solid;
    background: linear-gradient(#D6511D, #DF541E);
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 18px;
    font-weight: 700;
    a, a:link, a:visited {
      color: #FFF;
      border-left: #A23D15 1px solid;
    }
    a, a:link, a:visited a:last-child{
      color: #FFF;
      border-right: #A23D15 1px solid;
    }
}

}