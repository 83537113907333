.home-banner {
  background: linear-gradient(rgba(33, 27, 27, 0.77), rgba(35, 31, 28, 0.95)),  url('https://www.alligatorseptic.com/images/banner.jpg') no-repeat center top;
  height: 525px;
  background-size: cover;
}

.home-call-button {
    color: #000;
    text-shadow: 0px 2px 0px #fff;
}

.home-top-content {
  padding-top: 45px;
  max-width: 900px;

  @media (min-width: 768px){
    padding-left: 75px;
  }

  ul {
    padding-left: 15px;
  }
  li {
    list-style-type: none;
    font-size: 14px;
    line-height: 37px;
    color: #fff;
    padding: 4px 0 4px 50px;

    @media (min-width: 768px) {
      padding: 4px 0 19px 50px;
      display: inline-block;
      min-width: 49%;
    }
    p {
      width: 100%;
      padding-left: 18px;
      padding-right: 25px;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      font-size: 25px;
      display: inline-block;
      font-family: 'Oswald', sans-serif;
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
      background: -moz-linear-gradient(left, rgba(56,176,73,1) 0%, rgba(56,176,73,1) 81%, rgba(56,176,73,0.95) 82%, rgba(56,176,73,0) 100%);
      background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(56,176,73,1)), color-stop(81%,rgba(56,176,73,1)), color-stop(82%,rgba(56,176,73,0.95)), color-stop(100%,rgba(56,176,73,0)));
      background: -webkit-linear-gradient(left, rgba(56,176,73,1) 0%,rgba(56,176,73,1) 81%,rgba(56,176,73,0.95) 82%,rgba(56,176,73,0) 100%);
      background: -o-linear-gradient(left, rgba(56,176,73,1) 0%,rgba(56,176,73,1) 81%,rgba(56,176,73,0.95) 82%,rgba(56,176,73,0) 100%);
      background: -ms-linear-gradient(left, rgba(56,176,73,1) 0%,rgba(56,176,73,1) 81%,rgba(56,176,73,0.95) 82%,rgba(56,176,73,0) 100%);
      background: linear-gradient(to right, rgba(56,176,73,1) 0%,rgba(56,176,73,1) 81%,rgba(56,176,73,0.95) 82%,rgba(56,176,73,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#38b049', endColorstr='#0038b049',GradientType=1 );
    }
  }
  div {
    border: 1px solid #fff;
    display: inline-block;
    border-radius: 3px;
    text-shadow: 0px 2px 0px rgba(255, 255, 255, 0.004);
    margin-left: 23px;
    img {
      float: left;
    }
    p {
      font-family: 'Oswald', sans-serif;
      font-size: 24px;
      float: left;
      padding: 11px 15px 7px;
      font-weight: 700;
      border-color: rgb(255, 255, 255);
      background: #dadedd url('https://www.alligatorseptic.com/images/banner-tel-bg.jpg') repeat-x left top;
      line-height: 36px;
      margin-bottom: 0;
    }
  }
}

h4 {
  font-size: 25px;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-left: 28px;
  margin-bottom: 23px;
}

.home-cards-section {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  margin-bottom: 75px;

  @media (min-width: 768px){
    margin-top: -89px;
  }

  article {
    .card-top-photo {
      width: 100%;
    }
    .bottom-card {
      border-left: 1px solid #e9e9e9;
      border-right: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;
      text-align: center;
      width: 100%;
      box-shadow: 0px 25px 39px -28px #000000;

      .card-bottom-photo {
        margin-top: -32px;
      }

      h6 {
        text-transform: uppercase;
        font-size: 18px;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 700;
        line-height: 24px;
      }

      span {
        background: #eeeeee;
        height: 1px;
        display: inline-block;
        width: 71px;
        margin-bottom: 27px;
        margin-top: 13px;
      }
    }
  }
}

.home-bottom-content {
  .responsive-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  p {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    line-height: 24px;
    color: #5e5e5e;
    margin-bottom: 10px;
  }

  h3 {
    background: #fff;
    padding-right: 20px;
    font-size: 24px;
    font-family: 'Jockey One', sans-serif;
    text-transform: uppercase;
    display: inline-block;
    padding-left: 5px;
    color: #434343;
    font-weight: 500;
    text-align: left;
  }

  h5 {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #3f3f3f;
    margin-bottom: 13px;
  }

  .home-bottom-header-grid {
    display: grid;
    grid-template: auto 1fr / auto 1fr auto;
  }

  .stripped-background {
    height: 15px;
    margin-top: 8px;
    background-image: repeating-linear-gradient(125deg, #ccc, #ccc 2px, #FFF 2px, #FFF 5px);
  }

  .home-bottom-about-image {
    grid-area: image;
    border-right: 4px solid #d2d2d2;
    border-bottom: 4px solid #d2d2d2;
    padding: 1px;
    margin: auto auto;
  }

  .home-bottom-content-services-header {
    margin-bottom: 30px;
  }
  .home-our-services {
    li {
      font-size: 14px;
      display: inline-block;
      width: 48%;
      font-family: 'Open Sans', sans-serif;
      color: #5e5e5e;
      line-height: 36px;
      padding-left: 33px;
      background: url('https://www.alligatorseptic.com/images/right-icon.png') no-repeat left 8px;
    }
  }

  .fast-services {
    background: #e85606 url(https://www.alligatorseptic.com/images/map-bg.jpg) no-repeat left top;
    padding: 4px;

    .fast-services-text {
      border: 1px solid #f8cfb0;
      padding: 12px 21px 12px 20px;
    }

    h3 {
      font-size: 17px;
      text-transform: uppercase;
      color: #ffd801;
      font-family: 'Oswald', sans-serif;
      text-align: right;
      margin-bottom: 2px;
      display: block;
      background: none;
    }

    .mrg {
      margin-top: 21px;
    }

    span {
      font-size: 40px;
    }

    h2 {
      font-size: 26px;
      font-family: 'Jockey One', sans-serif;
      text-shadow: 1px 1.732px 0px #a93c00;
      color: #fff;
      text-transform: uppercase;
      display: inline-block;
      margin-top: -29px;
      font-weight: 500;
      margin-left: 15px;
    }

    a {
      text-align: right;
      font-size: 14px;
      text-decoration: underline !important;
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      float: right;
    }

    .clear {
      clear: both;
    }

    .cf:after {
      clear: both;
    }
    .cf:before, .cf:after {
      content: "";
      display: table;
    }
    margin-bottom: 50px;
  }

  .home-bottom-right-panel {
    h6 {
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
      color: #080808;
      font-weight: normal;
      margin-bottom: 11px;
    }

    ul {
      margin-bottom: 16px;
      font-size: 0px;
      list-style: none;
      padding-left: 0px;
    }
    li {
      display: inline-block;
      background: #e85606;
      font-size: 14px;
      color: #fff;
      font-family: 'Open Sans', sans-serif;
      font-weight: 700;
      border-radius: 4px;
      padding: 4px 8px;
      margin-right: 4px;
    }

    .home-bottom-right-panel-cards {
      h5 {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        color: $alligator-orange;
        margin-bottom: 3px;
      }
      span {
        font-family: "Times New Roman";
        font-style: italic;
        display: block;
        color: $alligator-green;
        font-weight: bold;
        margin-bottom: 11px;
      }
      p {
        font-family: "Times New Roman";
        font-size: 14px;
        line-height: 20px;
        color: #838383;
      }
    }
  }
}

.iframe-minimap {
  padding: 4px;
  border: 1px solid #d2d2d2;
  margin-bottom: 22px;
  width: 335px;
  height: 220px;
}

.directions-button {
  font-family: 'Open Sans', sans-serif;
  font-weight: bolder;
  font-size: 12px;
  background: #e85606;
  color: #fff;
  text-shadow: 0px 1px 0px rgba(164, 58, 0, 0.004);
  text-transform: uppercase;
  border-bottom: 3px solid #a43a00;
  display: inline-block;
  padding: 6px 17px;
}

.home-bottom-header {
  margin-bottom: 30px;
  padding-right: 0;
}

.home-bottom-about-image{
  @media (max-width: 768px){
    display: none;
  }
}

.green-font {
  color: #2c953c;
}